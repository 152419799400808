import { validateConfig, withTryCatch } from './helpers/utilities';
import { CustomSwiper } from '../types/generated/customSwiper.schema';
import customSwiperJson from '../schemas/customSwiper.schema.json';
import { css } from 'emotion';
import { addCssToContainer } from './helpers/domHelpers';

const SWIPER_DATA_ID = 'swiperCssStylesheet';

const customCss = css`
  & .swiper-container {
    overflow: visible !important;
  }
  & .swiper-slide {
    width: 100%;
  }
  & .swiper-next,
  & .swiper-next.swiper-button-disabled,
  & .swiper-next:active,
  & .swiper-next:focus,
  & .swiper-prev,
  & .swiper-prev.swiper-button-disabled,
  & .swiper-prev:active,
  & .swiper-prev:focus {
    outline: none !important;
  }
  & .swiper-pagination {
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    white-space: nowrap;
  }
  & .swiper-pagination-bullet {
    background: none !important;
    outline: none !important;
    transition: transform 0.2s, left 0.2s;
    margin: 0 4px;
  }
`;

const main = async (c: CustomSwiper) => {
  const { default: Swiper } = await import('swiper/bundle');

  // VALIDATING
  const validated = (await validateConfig(c, customSwiperJson)) as CustomSwiper;
  const {
    args: { slidesPerView, spaceBetween, grabCursor, loop, autoplay },
    selectors: { swiperRoot },
  } = validated;

  //ADD CUSTOM CSS
  addCssToContainer(swiperRoot, customCss);

  let customSwiper = new Swiper(`${swiperRoot} .swiper-container`, {
    slidesPerView,
    spaceBetween,
    grabCursor,
    loop,
    autoplay,

    navigation: {
      nextEl: `${swiperRoot} .swiper-next`,
      prevEl: `${swiperRoot} .swiper-prev`,
    },

    pagination: {
      el: `${swiperRoot} .pagination-container`,
      clickable: true,
      renderBullet: function (index, className) {
        return `
               <div class="pagination-wrapper ${className}">
                    <div class="pagination-active"></div>
               </div>
               `;
      },
    },

    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });

  //disable autoPlay while mouse over
  //@ts-ignore
  customSwiper.el.onmouseenter = customSwiper.autoplay.stop;
  //@ts-ignore
  customSwiper.el.onmouseleave = customSwiper.autoplay.start;
};

export default withTryCatch(async (c: CustomSwiper) => {
  // ADDING SWIPER CSS TO HEAD
  if (!document.querySelector(`[data-id="${SWIPER_DATA_ID}"]`)) {
    let swiperCss = document.createElement('link');
    swiperCss.setAttribute('data-id', SWIPER_DATA_ID);
    swiperCss.rel = 'stylesheet';
    swiperCss.href = process.env.ASSET_URL + '/lpjs/swiper-bundle.min.css';
    swiperCss.onload = () => main(c);
    document.head.appendChild(swiperCss);
    return;
  }
  await main(c);
});
