import { addJsToHead } from '../helpers/domHelpers';

export default () => {
  addJsToHead(`
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: 1306127});
    !function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
      }
    }(document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/unip/1306127/tfa.js',
      'tb_tfa_script');
  `);
};
