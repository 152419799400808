import {
  LanguageSwitch,
  Selectors,
} from '../types/generated/languageSwitch.schema';
import {
  dontPick,
  forEachKeyVal,
  mapKeyVal,
  pick,
  validateConfig,
  withTryCatch,
} from './helpers/utilities';
import languageSwitchSchema from '../schemas/languageSwitch.schema.json';
import Errors from './helpers/errors';

const elementFromSelectors = <T>(
  selectors: T
): { [key: string]: HTMLElement } => {
  return mapKeyVal(selectors, (key, val) => {
    const el = document.querySelector(val);
    if (!el) {
      throw Errors.selectorNotFound(val);
    }
    return { [key]: el };
  });
};

export default withTryCatch(async (c: LanguageSwitch) => {
  const validated = (await validateConfig(
    c,
    languageSwitchSchema
  )) as LanguageSwitch;

  const { selectors, args } = validated;

  const elements = elementFromSelectors<Selectors>(selectors);

  if (!window.Weglot) {
    throw Errors.missingGlobal('Weglot');
  }

  // Remove activeClass from every Element
  forEachKeyVal(dontPick(['current', 'mobileCurrent'], elements), (_, val) => {
    val.classList.remove(args.activeClass);
  });

  const handleClick = (e: MouseEvent, isoKey: string) => {
    forEachKeyVal(
      pick(['current', 'mobileCurrent'], elements),
      (key, val) => (val.textContent = args.languageDisplayNames[isoKey])
    );
    forEachKeyVal(
      dontPick(['current', 'mobileCurrent'], elements),
      (key, val) => val.classList.remove(args.activeClass)
    );

    (<HTMLInputElement>e.currentTarget).classList.add(args.activeClass);
    window.Weglot.switchTo(isoKey);
  };

  const handleInitialized = () => {
    // Set current Switch to current language
    const currentLang = window.Weglot.getCurrentLang();

    // Set Navbar item to current language
    forEachKeyVal(
      pick(['current', 'mobileCurrent'], elements) as {
        [key: string]: any;
      },
      (key, val) => (val.textContent = args.languageDisplayNames[currentLang])
    );

    forEachKeyVal(
      dontPick(['current', 'mobileCurrent'], elements) as {
        [key: string]: any;
      },
      (key, val) => {
        // en | de | fr
        const isoKey = ['en', 'de', 'fr'].includes(key)
          ? key
          : key.substr(-2).toLowerCase();

        // Add activeClass to Element representing current language
        if (isoKey === currentLang) {
          val.classList.add(args.activeClass);
        }

        val.addEventListener('click', (e: MouseEvent) =>
          handleClick(e, isoKey)
        );
      }
    );
  };

  if (window.Weglot.initialized) {
    handleInitialized();
    return;
  }
  window.Weglot.on('initialized', handleInitialized);
});
