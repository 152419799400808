import { QueryCurrentLang } from '../types/generated/queryCurrentLang.schema';

const main = () => {
  const params = new URLSearchParams(window.location.search);
  const lang = params.get('lpAppLang');
  if (lang === 'en' || lang === 'de' || lang === 'fr') {
    window.Weglot.switchTo(lang);
  }
};

export default async (_: QueryCurrentLang) => {
  if (!window.Weglot) {
    return;
  }

  if (window.Weglot.initialized) {
    main();
    return;
  }
  window.Weglot.on('switchersReady', main);
};
