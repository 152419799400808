import { validateConfig, withTryCatch } from '../helpers/utilities';
import addDeckFormJson from '../../schemas/addDeckForm.schema.json';
import { AddKlaviyoDeckForm } from '../../types/generated/addDeckForm.schema';
import customCss from './addDeckFormCss';
import Errors from '../helpers/errors';
import { addCssToContainer, addScriptToHead } from '../helpers/domHelpers';
import { LOCAL_STORAGE } from '../config/config';

const KLAVIYO_DATA_ID = 'klaviyoJsScript';
const KLAVIYO_SRC =
  'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Rwid85';

const handleLangChange = ({ selectors, args }: AddKlaviyoDeckForm) => {
  const currentLang =
    window.Weglot.getCurrentLang() ||
    localStorage.getItem(LOCAL_STORAGE.wglang) ||
    'en';
  const wrappers = document.querySelectorAll(selectors.sendDeckContainer);

  if (!wrappers) {
    throw Errors.selectorNotFound(selectors.sendDeckContainer);
  }

  wrappers.forEach(el => {
    const children = el.children;
    Array.from(children).forEach(child => {
      if (
        !(child as HTMLElement).classList.contains(
          selectors.klaviyoForms[currentLang as 'en' | 'de' | 'fr']
        )
      ) {
        (child as HTMLElement).style.setProperty('display', 'none');
      } else {
        (child as HTMLElement).style.setProperty('display', 'block');
        setTimeout(() => {
          if (!child.hasChildNodes()) {
            child.appendChild(document.createTextNode(args!.adblockMsg));
          }
        }, 4000);
      }
    });
  });
};

const addKlaviyoJs = () => {
  if (!document.querySelector(`[data-id="${KLAVIYO_DATA_ID}"]`)) {
    addScriptToHead(KLAVIYO_SRC, { ['data-id']: KLAVIYO_DATA_ID });
  }
};

export default withTryCatch(async (c: AddKlaviyoDeckForm) => {
  if (!window.Weglot) {
    throw Errors.missingGlobal('Weglot');
  }
  addKlaviyoJs();
  const validated = await validateConfig(c, addDeckFormJson);
  addCssToContainer(c.selectors.sendDeckContainer, customCss);
  handleLangChange(validated as AddKlaviyoDeckForm);
  window.Weglot.on('languageChanged', () =>
    handleLangChange(validated as AddKlaviyoDeckForm)
  );
});
