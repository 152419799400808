import { validateConfig, withTryCatch } from './helpers/utilities';
import { InitBackgroundColor } from '../types/generated/initBgColor.schema';
import initBgColorJson from '../schemas/initBgColor.schema.json';
import Errors from './helpers/errors';

export default withTryCatch(async (c: InitBackgroundColor) => {
  const validated = (await validateConfig(
    c,
    initBgColorJson
  )) as InitBackgroundColor;

  const dataAttr = validated?.args?.dataAttr;

  const isInViewport = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();

    const halfH = window.innerHeight / 2;

    return rect.top < halfH && rect.bottom >= 0 + halfH;
  };

  const sectionsWithBackgroundTransition = document.querySelectorAll(
    '[' + dataAttr + ']'
  );

  sectionsWithBackgroundTransition.forEach(node => {
    if (isInViewport(node as HTMLElement)) {
      setTimeout(() => {
        document.body.style.backgroundColor = node.getAttribute(
          'data-scroll-bg'
        )!;
      }, 1000);
    }
  });
});
