import { validateConfig, withTryCatch } from './helpers/utilities';
import persistInviteCodeJson from '../schemas/persistInviteCode.schema.json';
import { PersistInviteCode } from '../types/generated/persistInviteCode.schema';

export default withTryCatch(async (c: PersistInviteCode) => {
  await validateConfig(c, persistInviteCodeJson);

  const params = new URLSearchParams(window.location.search);
  const lpinvite = params.get('lpinvite');
  if (!lpinvite) return;
  sessionStorage.setItem('lpinvite', lpinvite);
});
