import {
  forEachKeyVal,
  validateConfig,
  withTryCatch,
} from './helpers/utilities';
import Errors from './helpers/errors';
import { LocaliseDomNode } from '../types/generated/localiseDomNode.schema';
import localiseDomNodeJson from '../schemas/localiseDomNode.schema.json';

const handleLangChange = (c: LocaliseDomNode) => {
  const currentLang =
    window.Weglot.getCurrentLang() || localStorage.getItem('wglang') || 'en';

  const containers: {
    en: HTMLElement | null;
    de: HTMLElement | null;
    fr: HTMLElement | null;
  } = {
    en: document.querySelector(c.selectors.en),
    fr: document.querySelector(c.selectors.fr),
    de: document.querySelector(c.selectors.de),
  };

  forEachKeyVal(containers, (key, val) => {
    if (!val) {
      throw Errors.selectorNotFound(c.selectors[key]);
    }
    if (key === currentLang) {
      val.style.setProperty('display', 'block');
    } else {
      val.style.setProperty('display', 'none');
    }
  });
};

const localiseDomNode = async (c: LocaliseDomNode) => {
  if (!window.Weglot) {
    throw Errors.missingGlobal('Weglot');
  }
  await validateConfig(c, localiseDomNodeJson);
  handleLangChange(c);
  window.Weglot.on('languageChanged', () => handleLangChange(c));
};

export default withTryCatch(localiseDomNode);
