import {
  DOM_TEST_BASE_ERROR_MSG,
  SENTRY_DSN,
  WEGLOT_ERROR_MSGS,
  LINK_ERROR_MSGS,
} from '../config/config';
import * as Sentry from '@sentry/browser';

const isValidMsg = (message: string) =>
  Object.values(WEGLOT_ERROR_MSGS).includes(message) ||
  message.includes(DOM_TEST_BASE_ERROR_MSG);

const getSentryEnv = () => {
  switch (window.location.origin) {
    case 'https://livingpackets.com':
      return 'production';
    case 'https://livingpackets.webflow.io':
      return 'staging';
    default:
      return 'development';
  }
};

export const initSentry = () => {
  window.SentryInitialised = true;
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getSentryEnv(),
    debug: window.DebugSentry || false,
    beforeSend(event: any, hint: any) {
      let error = hint.originalException;
      console.log('before event returned 1');
      console.log(event);
      console.log(error);
      console.log(hint);
      console.log(LINK_ERROR_MSGS.error404);
      console.log('before event returned 2');
      if (
        (error && error.message && isValidMsg(error.message)) ||
        event.level === 'error'
      ) {
        console.log('event returned');
        return event;
      }
      return null;
    },
  });
};
