import { validateConfig, withTryCatch } from './helpers/utilities';
import { CopyLink } from '../types/generated/copyLink.schema';
import copyLinkJson from '../schemas/copyLink.schema.json';
import Errors from './helpers/errors';
import { MODULES } from './init';

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const copyLink = async (c: CopyLink) => {
  const validated = (await validateConfig(c, copyLinkJson)) as CopyLink;
  const form = document.querySelector(validated.selectors.form);
  if (!form) throw Errors.selectorNotFound(validated.selectors.form);
  const inputField = form.querySelector('input');
  const button = form.querySelector('a');
  const success = form.querySelector('.success-message') as HTMLElement;

  if (!inputField)
    throw Errors.genericError(MODULES.copyLink, 'input not found');
  if (!button) throw Errors.genericError(MODULES.copyLink, 'button not found');
  if (!success)
    throw Errors.genericError(MODULES.copyLink, 'success msg not found');
  const text = inputField.getAttribute('placeholder');
  if (!text) throw Errors.genericError(MODULES.copyLink, 'copy not foound');

  inputField.setAttribute('disabled', 'true');
  button.setAttribute('href', 'javascript:void(0)');
  button.onclick = () => {
    copyToClipboard(text);
    button.style.setProperty('display', 'none');
    success.style.setProperty('display', 'block');
    setTimeout(() => {
      button.style.setProperty('display', 'inline-block');
      success.style.setProperty('display', 'none');
    }, 3000);
  };
};

export default withTryCatch(copyLink);
