import { validateConfig, withTryCatch } from './helpers/utilities';
import { ForceLang } from '../types/generated/forceLang.schema';
import forcelangJson from '../schemas/forceLang.schema.json';
import Errors from './helpers/errors';

export default withTryCatch(async (c: ForceLang) => {
  const validated = (await validateConfig(c, forcelangJson)) as ForceLang;

  const isoKey = validated?.args?.forcedLanguage;

  if (!window.Weglot) {
    throw Errors.missingGlobal('Weglot');
  }

  const handleInitialized = () => {
    window.Weglot.switchTo(isoKey);
  };

  if (window.Weglot.initialized) {
    handleInitialized();
    return;
  }

  window.Weglot.on('initialized', handleInitialized);
});
