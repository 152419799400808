import { validateConfig, withTryCatch } from './helpers/utilities';
import { OpenAccordionButton } from '../types/generated/openAccordionButton.schema';
import openAccordionButtonJson from '../schemas/openAccordionButton.schema.json';
import { ignoreNextOnError } from '@sentry/browser/dist/helpers';

export default withTryCatch(async (c: OpenAccordionButton) => {
  const validated = (await validateConfig(
    c,
    openAccordionButtonJson
  )) as OpenAccordionButton;

  const buttonId = validated?.args?.buttonId;
  const targetAccordionId = validated?.args?.targetAccordionId;
  const accordionWrapper = validated?.args?.accordionWrapper;

  document.getElementById(buttonId)?.addEventListener('click', e => {
    e.preventDefault();

    const targetAccordion = document.getElementById(
      targetAccordionId
    ) as HTMLElement;
    const accordionHeader = targetAccordion?.querySelector(
      '.dropdown-header'
    ) as HTMLElement;

    const activeBody = targetAccordion.querySelector(
      '.dropdown-body'
    ) as HTMLElement;
    const activeIcon = targetAccordion.querySelector(
      '.dropdown-icon'
    ) as HTMLElement;

    const isOpen = activeBody.classList.contains('accordion-open');

    const wrapperNode = targetAccordion.closest(
      accordionWrapper
    ) as HTMLElement;

    wrapperNode.querySelectorAll('.dropdown-body')?.forEach(bodyItem => {
      const bi = bodyItem as HTMLElement;
      bi.classList.remove('accordion-open');
    });

    wrapperNode.querySelectorAll('.dropdown-icon')?.forEach(dropdownIcon => {
      const di = dropdownIcon as HTMLElement;
      di.classList.remove('rotate-plus');
    });

    console.log(activeBody, isOpen);

    if (!isOpen) {
      activeBody.classList.add('accordion-open');
      activeIcon.classList.add('rotate-plus');
    }

    setTimeout(() => {
      accordionHeader.scrollIntoView({
        behavior: 'smooth',
      });
    }, 1000);
  });
});
