import {
  WEGLOT_PUBLIC_API_KEY,
  WEGLOT_TRANS_TEXT,
  WEGLOT_ERROR_MSGS,
  LINK_ERROR_MSGS,
} from './config/config';
import * as Sentry from '@sentry/browser';
import { SentryWeglotErrorHandling } from '../types/generated/sentryWeglotErrorHandling.schema';
import { withTryCatch } from './helpers/utilities';
import { initSentry } from './helpers/sentry';
import { Severity } from '@sentry/browser';

const WEGLOT_STATUS_URL =
  'https://app.hyperping.io/api/v1/public/services/status.weglot.com';
const WEGLOT_TRANS_URL =
  'https://api.weglot.com/translate?api_key=' + WEGLOT_PUBLIC_API_KEY;

export default withTryCatch((c: SentryWeglotErrorHandling) => {
  if (!window.SentryInitialised) {
    initSentry();
  }

  const fetchWeglotStatus = () => {
    fetch(WEGLOT_STATUS_URL)
      .then(res => res.json())
      .then(json => {
        const data = json.data;
        if (data && data.services) {
          const servicesArray = data.services;

          // "alias": "Dashboard",
          // "id": "comp_9NLap7Pf2vkKdS",
          const dashboard = servicesArray.find(element => element.id == "comp_9NLap7Pf2vkKdS");
         
          // "alias": "API Translate",
          //"id": "comp_zk0maSIIx86pCn",
          const translateApi = servicesArray.find(element => element.id == "comp_zk0maSIIx86pCn");

          if (!dashboard || !translateApi) {
            //console.log('not parsed');
            Sentry.captureException(
              new Error(WEGLOT_ERROR_MSGS.statusEndpointNotParsed)
            );
            return;
          }
          if (dashboard.status !== "up") {
            //console.log('dashboard down');
            Sentry.captureException(
              new Error(WEGLOT_ERROR_MSGS.apiDashboardNotUp)
            );
          }
          if (translateApi.status !== "up") {
            //console.log('translate down');
            Sentry.captureException(
              new Error(WEGLOT_ERROR_MSGS.apiTranslateNotUp)
            );
          }
          return;
        } else {
          //console.log('not parsed');
          Sentry.captureException(
            new Error(WEGLOT_ERROR_MSGS.statusEndpointNotParsed)
          );
        }
      })
      .catch(e => {
        //console.log(e);
        Sentry.captureException(new Error(e));
        // Disabled for now...
        // Sentry.captureException(
        //   new Error(WEGLOT_ERROR_MSGS.statusEndpointFailed)
        // );
      });
  };

  const attemptWeglotTranslation = () => {
    fetch(WEGLOT_TRANS_URL, {
      method: 'post',
      body: JSON.stringify({
        l_from: 'en',
        l_to: 'de',
        request_url: 'https://de.livingpackets.com/',
        words: [{ w: WEGLOT_TRANS_TEXT, t: 1 }],
      }),
    }).catch(e => {
      e.message = Sentry.captureException(
        new Error(WEGLOT_ERROR_MSGS.translationEndpointFails)
      );
    });
  };

  const SELECTORS_LIST: { [key: string]: any } = {
    buttonSharingTest: '#buttonSharingTest',
    buttonProfessionalTest: '#buttonProfessionalTest',
    buttonSharing: '.hero_buttons_wrapper a:nth-child(1)',
    buttonProfessionnal: '.hero_buttons_wrapper a:nth-child(2)',
    linkSharing: '#sharing',
    linkProfessional: '#professional',
    linkSharingMobile: '#sharing-mobile',
    linkProfessionalMobile: '#professional-mobile',
  };

  function UrlExists(url: any) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    try {
      if (http.status != 404) {
        console.log('link is OK');
      } else {
        console.log('link is 404');
        //Sentry.captureEvent(new Error(LINK_ERROR_MSGS.error404));
        console.log('before scope');

        /* Sentry.withScope(function (scope) {
        console.log('in scope 1');

        scope.setLevel(Sentry.Severity.Critical);
        Sentry.captureEvent(new Error(LINK_ERROR_MSGS.error404));
        Sentry.captureMessage(LINK_ERROR_MSGS.error404);
        console.log('in scope 2');

        Sentry.captureException(new Error(LINK_ERROR_MSGS.error404));
        console.log('in scope 3');
        
      });*/
        throw new Error(url + ' replied 404');
      }
    } catch (err) {
      console.log('My error');
      console.log(err);
      Sentry.captureEvent(new Error(LINK_ERROR_MSGS.error404));
      Sentry.captureException(new Error(LINK_ERROR_MSGS.error404));
      Sentry.captureException(err);
      //Sentry.captureMessage(LINK_ERROR_MSGS.error404);
    }
  }

  window.addEventListener('DOMContentLoaded', () => {
    //console.log('DOM fully loaded and parsed');
    for (let selector in SELECTORS_LIST) {
      if (document.querySelector(SELECTORS_LIST[selector])) {
        let link = document
          .querySelector(SELECTORS_LIST[selector])!
          .getAttribute('href');
        document
          .querySelector(SELECTORS_LIST[selector])
          ?.addEventListener('click', () => {
            //console.log(link);
            UrlExists(link);
          });
      }
    }
  });
  console.log('init10');
  fetchWeglotStatus();
  attemptWeglotTranslation();
});
