import { validateConfig, withTryCatch } from './helpers/utilities';
import { addScriptToHead } from './helpers/domHelpers';
import { AddCrispChat } from '../types/generated/addCrispChat.schema';
import addCrispChatJson from '../schemas/addCrispChat.schema.json';

const addCrispChat = async (c: AddCrispChat) => {
  const validated = (await validateConfig(c, addCrispChatJson)) as AddCrispChat;
  if (validated.args.excludedPaths.includes(window.location.pathname)) {
    return;
  }
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = '115c2c3a-1065-4a45-ac85-76fa58e592a6';
  addScriptToHead('https://client.crisp.chat/l.js', { ['async']: true });
  console.log('crisp added');
};

export default withTryCatch(addCrispChat);
