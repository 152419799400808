import { LOCAL_STORAGE } from './config/config';
import { validateConfig, withTryCatch } from './helpers/utilities';
import Errors from './helpers/errors';
import { CookieNotice } from '../types/generated/cookieNotice.schema';
import cookieNoticeJson from '../schemas/cookieNotice.schema.json';
import initLpJs from './init';

const lpjsWithoutTypes = (input: any) => initLpJs(input);

const initCookieNotice = (c: CookieNotice) => {
  const container = document.querySelector(
    c.selectors.cookieNotice
  ) as HTMLElement;
  if (!container) throw Errors.selectorNotFound(c.selectors.cookieNotice);

  container.style.setProperty('transition', '0.3s ease-in-out');
  if (!container.querySelector(c.selectors.button))
    throw Errors.selectorNotFound(
      c.selectors.cookieNotice + '>' + c.selectors.button
    );
  (container.querySelector(c.selectors.button) as HTMLElement).onclick = () => {
    localStorage.setItem(LOCAL_STORAGE.lpCookies, 'true');
    c.args?.afterApproval && lpjsWithoutTypes(c.args.afterApproval);
  };
  return container;
};

const main = async (c: CookieNotice) => {
  const validated = (await validateConfig(c, cookieNoticeJson)) as CookieNotice;
  const container = initCookieNotice(validated);
  const accepted = localStorage.getItem(LOCAL_STORAGE.lpCookies);
  if (!accepted) {
    container.classList.remove(c.selectors.cookieNotice.substr(1));
    return;
  }
  validated.args?.afterApproval &&
    lpjsWithoutTypes(validated.args.afterApproval);
};

export default withTryCatch(main);
