import { addJsToHead } from '../helpers/domHelpers';

export default () => {
  addJsToHead(`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2027570663977799');
      setTimeout(function(){
        fbq('track', 'PageView');
      }, 5000);
  `);
  let noScript = document.createElement('noscript');
  noScript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=2027570663977799&ev=PageView&noscript=1"/>`;
  document.head.appendChild(noScript);
};
