import navbar from './navbar';
import { Navbar } from '../types/generated/navbar.schema';
import persistInviteCode from './persistInviteCode';
import { PersistInviteCode } from '../types/generated/persistInviteCode.schema';
import { AddKlaviyoDeckForm } from '../types/generated/addDeckForm.schema';
import addDeckForm from './addDeckForm/addDeckForm';
import { CustomSwiper } from '../types/generated/customSwiper.schema';
import customSwiper from './customSwiper';
import { SentryWeglotErrorHandling } from '../types/generated/sentryWeglotErrorHandling.schema';
import sentryWeglotErrorHandling from './sentryWeglotErrorHandling';
import { DOMTest } from '../types/generated/domTest.schema';
import domTest from './domTest';
import { ToastMessage } from '../types/generated/toastMessage.schema';
import { InviteCodeMsg } from '../types/generated/inviteCodeMessage.schema';
import toastMessage from './toastMessage';
import inviteCodeMsg from './inviteCodeMsg';
import { LanguageSwitch } from '../types/generated/languageSwitch.schema';
import languageSwitch from './languageSwitch';
import { LoadTracking } from '../types/generated/loadTracking.schema';
import loadTracking from './loadTracking/loadTracking';
import { TotalAmount } from '../types/generated/totalAmount.schema';
import totalAmount from './totalAmount';
import { CopyLink } from '../types/generated/copyLink.schema';
import copyLink from './copyLink';
import { FetchReactComponents } from '../types/generated/fetchReactComponents.schema';
import fetchReactComponents from './fetchReactComponents';
import { LocaliseDomNode } from '../types/generated/localiseDomNode.schema';
import localiseDomNode from './localiseDomNode';
import { AddCrispChat } from '../types/generated/addCrispChat.schema';
import addCrispChat from './addCrispChat';
import { CookieNotice } from '../types/generated/cookieNotice.schema';
import cookieNotice from './cookieNotice';
import { QueryCurrentLang } from '../types/generated/queryCurrentLang.schema';
import queryCurrentLang from './queryCurrentLang';
import { ForceLang } from '../types/generated/forceLang.schema';
import forceLang from './forceLang';
import { InitBackgroundColor } from '../types/generated/initBgColor.schema';
import initBgColor from './initBgColor';
import { CloseAccordions } from '../types/generated/closeAccordions.schema';
import closeAccordions from './closeAccordions';
import { OpenAccordionButton } from '../types/generated/openAccordionButton.schema';
import openAccordionButton from './openAccordionButton';

export enum MODULES {
  navbar = 'NAVBAR',
  persistInviteCode = 'PERSIST_INVITE_CODE',
  addDeckFrom = 'KLAVIYO_DECK_FORM',
  customSwiper = 'CUSTOM_SWIPER',
  sentryWeglotErrorHandling = 'SENTRY_WEGLOT_ERRORS',
  domTest = 'DOM_TEST',
  toastMessage = 'TOAST_MESSAGE',
  inviteCodeMsg = 'INVITE_CODE_MSG',
  languageSwitch = 'LANGUAGE_SWITCH',
  loadTracking = 'LOAD_TRACKING',
  totalAmount = 'TOTAL_AMOUNT',
  copyLink = 'COPY_LINK',
  fetchReactComponents = 'FETCH_REACT_COMPONENTS',
  localiseDomNode = 'LOCALISE_DOM_NODE',
  addCripsChat = 'CRISP_CHAT',
  cookieNotice = 'COOKIE_NOTICE',
  queryCurrentLang = 'QUERY_CURRENT_LANG',
  forceLang = 'FORCE_LANG',
  initBackgroundColor = 'INIT_BACKGROUND_COLOR',
  closeAccordions = 'CLOSE_ACCORDIONS',
  openAccordionButton = 'OPEN_ACCORDION_BUTTON',
}

export type TConfigs =
  | Navbar
  | PersistInviteCode
  | AddKlaviyoDeckForm
  | SentryWeglotErrorHandling
  | DOMTest
  | ToastMessage
  | InviteCodeMsg
  | CustomSwiper
  | LanguageSwitch
  | TotalAmount
  | CopyLink
  | LoadTracking
  | FetchReactComponents
  | LocaliseDomNode
  | AddCrispChat
  | CookieNotice
  | QueryCurrentLang
  | ForceLang
  | InitBackgroundColor
  | CloseAccordions
  | OpenAccordionButton;

export interface IAdditionalOptions {
  benchMarkPerf?: boolean;
}

export default (config: (TConfigs & IAdditionalOptions)[]) => {
  config.forEach(async c => {
    switch (c.module) {
      case MODULES.navbar:
        await navbar(c);
        return;
      case MODULES.persistInviteCode:
        await persistInviteCode(c);
        return;
      case MODULES.addDeckFrom:
        await addDeckForm(c);
        return;
      case MODULES.customSwiper:
        await customSwiper(c);
        return;
      case MODULES.sentryWeglotErrorHandling:
        await sentryWeglotErrorHandling(c);
        return;
      case MODULES.domTest:
        await domTest(c);
        return;
      case MODULES.toastMessage:
        await toastMessage(c);
        return;
      case MODULES.inviteCodeMsg:
        await inviteCodeMsg(c);
        return;
      case MODULES.languageSwitch:
        await languageSwitch(c);
        return;
      case MODULES.totalAmount:
        await totalAmount(c);
        return;
      case MODULES.copyLink:
        await copyLink(c);
        return;
      case MODULES.loadTracking:
        await loadTracking(c);
        return;
      case MODULES.fetchReactComponents:
        await fetchReactComponents(c);
        return;
      case MODULES.localiseDomNode:
        await localiseDomNode(c);
        return;
      case MODULES.addCripsChat:
        await addCrispChat(c);
        return;
      case MODULES.cookieNotice:
        await cookieNotice(c);
        return;
      case MODULES.queryCurrentLang:
        await queryCurrentLang(c);
        return;
      case MODULES.forceLang:
        await forceLang(c);
        return;
      case MODULES.initBackgroundColor:
        await initBgColor(c);
        return;
      case MODULES.closeAccordions:
        await closeAccordions(c);
        return;
      case MODULES.openAccordionButton:
        await openAccordionButton(c);
        return;
      default:
        console.error(`Module ${c.module} doesn't exist!`);
    }
  });
};
