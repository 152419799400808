import '@babel/polyfill';
import init from './modules/init';

declare global {
  interface Window {
    lpjs: { [key: string]: any };
  }
}

const lpjs = { init };

window.lpjs = lpjs;
