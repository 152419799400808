import { css } from 'emotion';

const customCss = css`
  & div,
  & input,
  & label,
  & button,
  & ::placeholder {
    font-family: 'Typetype tt norms pro', sans-serif !important;
    font-weight: 500 !important;
  }
  & ::placeholder {
    opacity: 0.6 !important;
    font-size: 1rem !important;
    color: black !important;
  }
  & form {
    max-width: unset !important;
  }
  & button {
    padding: 1.125rem 1.563rem 1rem !important;
    background: #232323 !important;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-decoration: none;
    text-transform: uppercase;
  }
  & input {
    color: black !important;
    width: 100% !important;
    text-transform: none;
    height: 3.125rem !important;
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
    border-style: none none solid !important;
    border-width: 1px 1px 2px !important;
    border-color: #25b676 #25b676 #000 !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
    outline: none !important;
  }
  & label {
    font-size: 0.75rem !important;
    font-weight: bold !important;
    color: black !important;
  }
`;

export default customCss;
