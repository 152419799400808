import { LoadTracking } from '../../types/generated/loadTracking.schema';
import { validateConfig, withTryCatch } from '../helpers/utilities';
import loadTrackingJson from '../../schemas/loadTracking.schema.json';
import { addScriptToHead, addScriptBody } from '../helpers/domHelpers';
import { TRACKING_LINKS } from '../config/config';
import initGoogleAnalytics from './initGoogleAnalytics';
import initGoogleAds from './initGoogleAds';
import initTaboola from './initTaboola';
import initHotjar from './initHotjar';
import initFbPixel from './initFbPixel';
import initProveSrc from './initProveSrc';
import initHyros from './initHyros';

enum TrackingTools {
  bugherd = 'BUGHERD',
  googleOptimize = 'GOOGLE_OPTIMIZE',
  sniply = 'SNIPLY',
  googleAnalytics = 'GOOGLE_ANALYTICS',
  googleAds = 'GOOGLE_ADS',
  taboola = 'TABOOLA',
  hotjar = 'HOTJAR',
  facebookPixel = 'FACEBOOK_PIXEL',
  proveSrc = 'PROVE_SRC',
  hyros = 'HYROS',
  klaviyo = 'KLAVIYO',
}

const loadTracking = async (c: LoadTracking) => {
  await validateConfig(c, loadTrackingJson);
  c.args.tools.forEach(tool => {
    switch (tool) {
      case TrackingTools.bugherd:
        addScriptToHead(TRACKING_LINKS.bugherd, { ['async']: true });
        break;
      case TrackingTools.googleOptimize:
        addScriptToHead(TRACKING_LINKS.googleOptimize);
        break;
      case TrackingTools.sniply:
        addScriptToHead(TRACKING_LINKS.sniply);
        break;
      case TrackingTools.googleAnalytics:
        initGoogleAnalytics();
        break;
      case TrackingTools.googleAds:
        initGoogleAds();
        break;
      case TrackingTools.taboola:
        initTaboola();
        break;
      case TrackingTools.hotjar:
        initHotjar();
        break;
      case TrackingTools.facebookPixel:
        initFbPixel();
        break;
      case TrackingTools.proveSrc:
        initProveSrc();
        break;
      case TrackingTools.hyros:
        initHyros();
        break;
      case TrackingTools.klaviyo:
        addScriptBody(TRACKING_LINKS.klaviyo);
      default:
        console.warn('Tracking tool ' + tool + ' is not supported.');
    }
  });
};

export default withTryCatch(loadTracking);
