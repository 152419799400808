import toastMessage from './toastMessage';
import { InviteCodeMsg } from '../types/generated/inviteCodeMessage.schema';
import { validateConfig, withTryCatch } from './helpers/utilities';
import inviteCodeMsgJson from '../schemas/inviteCodeMessage.schema.json';

export default withTryCatch(async function (c: InviteCodeMsg) {
  const validated = (await validateConfig(
    c,
    inviteCodeMsgJson
  )) as InviteCodeMsg;
  const params = new URLSearchParams(window.location.search);
  const lpInviteCode = params.get('lpinvite');
  if (lpInviteCode) {
    toastMessage({ ...validated, module: 'TOAST_MESSAGE' });
  }
});
