import { validateConfig, withTryCatch } from './helpers/utilities';
import { FetchReactComponents } from '../types/generated/fetchReactComponents.schema';
import { addScriptBody, pollForGlobal } from './helpers/domHelpers';
import Errors from './helpers/errors';
import { MODULES } from './init';
import fetchReactComponentsJson from '../schemas/fetchReactComponents.schema.json';

const getEnv = (env: string) => {
  switch (env) {
    case 'PRD':
      return '.';
    case 'TST':
      return '.test.';
    case 'DEV':
      return '.dev.';
    default:
      return '.';
  }
};

const fetchReactComponents = async (c: FetchReactComponents) => {
  await validateConfig(c, fetchReactComponentsJson);
  const env = c.args.env;
  const baseUrl = `https://assets${getEnv(env)}livingpackets.com`;
  fetch(`${baseUrl}/wf-react-components/build-manifest.json`)
    .then(res => res.json())
    .then(manifest => {
      if (manifest?.main?.js && manifest.main.js[0]) {
        const jsChunkPath = manifest.main.js[0];
        addScriptBody(baseUrl + jsChunkPath);
        return;
      }
      throw Errors.genericError(
        MODULES.fetchReactComponents,
        'React build-manifest.json not found'
      );
    })
    .catch(e =>
      Errors.genericError(MODULES.fetchReactComponents, e.toString())
    );
};

const checkReact = async () => {
  if (!window.React) {
    addScriptBody('https://unpkg.com/react@16/umd/react.production.min.js', {
      ['crossorigin']: true,
    });
    await pollForGlobal({
      globalName: 'React',
    });
  }
};

const checkReactDom = async () => {
  if (!window.ReactDOM) {
    addScriptBody(
      'https://unpkg.com/react-dom@16/umd/react-dom.production.min.js',
      {
        ['crossorigin']: true,
      }
    );
    await pollForGlobal({
      globalName: 'ReactDOM',
    });
  }
};

const main = async (c: FetchReactComponents) => {
  const validated = (await validateConfig(
    c,
    fetchReactComponentsJson
  )) as FetchReactComponents;
  await checkReact();
  if (!window.React) {
    throw Errors.missingGlobal('React');
  }
  await checkReactDom();
  if (!window.ReactDOM) {
    throw Errors.missingGlobal('ReactDOM');
  }
  fetchReactComponents(validated);
};

export default withTryCatch(main);
