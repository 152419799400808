import { MODULES } from '../init';

type TError = (s?: string) => Error;

interface IErrors {
  selectorNotFound: TError;
  invalidConfig: TError;
  missingGlobal: TError;
  requestFailed: TError;
  genericError: (module: MODULES, description: string) => Error;
}

export const LPJS_ERROR = 'LPJS_ERROR: ';

const Errors: IErrors = {
  selectorNotFound: selector =>
    new Error(LPJS_ERROR + `Selector${selector && ` "${selector}"`} not found`),
  invalidConfig: module =>
    new Error(
      LPJS_ERROR + `Config${module && ` for module "${module}"`} is invalid`
    ),
  missingGlobal: variable =>
    new Error(
      LPJS_ERROR +
        `Required global Variable${variable && ` "${variable}"`} is undefined`
    ),
  requestFailed: url =>
    new Error(LPJS_ERROR + `Request${url && ` to "${url}"`} failed `),
  genericError: (module, description) =>
    new Error(LPJS_ERROR + `Error in module ${module}: ${description}`),
};

export default Errors;
