export const WEGLOT_PUBLIC_API_KEY = 'wg_054923d87593d5836835c64a4a036b3d3';

export const WEGLOT_TRANS_TEXT = 'Profit Now';

export const WEGLOT_ERROR_MSGS = {
  apiDashboardNotUp: 'Weglot Dashboard is not indicating up',
  apiTranslateNotUp: 'Weglot API Translate is not indicating up',
  statusEndpointNotParsed:
    'Hyperping status endpoint seems to have changed format',
  statusEndpointFailed: 'Hyperping status endpoint returned error',
  translationEndpointFails: 'Weglot test translation failed',
};

export const LINK_ERROR_MSGS = {
  error404: '404 - Page not found',
};

export const SENTRY_DSN =
  'https://9116dbc036db499d843e15e2556c039d@o422670.ingest.sentry.io/5421284';

export const DOM_TEST_BASE_ERROR_MSG = 'DOMTest failed: ';

export const TRACKING_LINKS = {
  googleOptimize: 'https://www.googleoptimize.com/optimize.js?id=GTM-53TM5DM',
  hyros:
    'https://174966.tracking.markethero.io/v1/lst/universal-script?ph=73165e3bef611254cc842404fc1866ee91f7d140343d79630874e517577ea4a5&tag=!hyros',
  sniply: 'https://gosniply.com/site/5ef08484eb33fa358ab21a8c.js',
  googleTagManager: 'https://www.googletagmanager.com/gtag/js?id=AW-755222937',
  bugherd: 'https://www.bugherd.com/sidebarv2.js?apikey=x3dhntjncpdautlieo7kcg',
  klaviyo: 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Rwid85',
};

export const LOCAL_STORAGE = {
  lpCookies: 'lpCookies',
  wglang: 'wglang',
};
