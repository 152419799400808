import { ToastMessage } from '../types/generated/toastMessage.schema';
import { validateConfig, withTryCatch } from './helpers/utilities';
import toastMessageJson from '../schemas/toastMessage.schema.json';

const closeMsg = (msg: HTMLElement, c: ToastMessage) => {
  if (c.args.rememberClosed) {
    localStorage.setItem(
      `${c.selectors.rootContainer.substr(1)}-closed`,
      'true'
    );
  }
  msg.style.setProperty('transition', 'opacity 0.3s ease-in');
  msg.style.setProperty('opacity', '0');
  setTimeout(() => {
    msg.style.setProperty('display', 'none');
  }, 600);
};

interface ICopy {
  header?: string;
  subheader: string;
}

interface ITransCopy {
  en: ICopy;
  de: ICopy;
  fr: ICopy;
}

const setCopy = (
  copy: ITransCopy,
  lang: string,
  header: HTMLParagraphElement,
  subheader: HTMLParagraphElement
) => {
  switch (lang) {
    case 'en':
      header.innerText = copy.en.header || '';
      subheader.innerText = copy.en.subheader;
      break;
    case 'fr':
      header.innerText = copy.fr.header || '';
      subheader.innerText = copy.fr.subheader;
      break;
    case 'de':
      header.innerText = copy.de.header || '';
      subheader.innerText = copy.de.subheader;
      break;
    default:
      header.innerText = copy.en.header || '';
      subheader.innerText = copy.en.subheader;
  }
};

const getMessageContainers = (c: ToastMessage) => {
  let msg, closeIcon, header, subheader;
  msg = document.querySelector(c.selectors.rootContainer) as HTMLElement;
  closeIcon = document.querySelector(c.selectors.closeIcon);
  if (msg) {
    const copyContainer = msg.querySelector(c.selectors.textContainer);
    if (copyContainer) {
      header = copyContainer.querySelectorAll('p')[0];
      subheader = copyContainer.querySelectorAll('p')[1];
    }
  }
  return { msg, closeIcon, header, subheader };
};

const onLangChange = (c: ToastMessage) => {
  const { header, subheader } = getMessageContainers(c);
  const currentLang = window.Weglot.getCurrentLang();
  if (header && subheader) {
    setCopy(c.args.copy, currentLang, header, subheader);
  }
};

const main = (c: ToastMessage) => {
  if (
    localStorage.getItem(`${c.selectors.rootContainer.substr(1)}-closed`) ===
    'true'
  ) {
    return;
  }
  const currentLang = window.Weglot.getCurrentLang();
  const { msg, closeIcon, header, subheader } = getMessageContainers(c);
  if (msg) {
    if (header && subheader) {
      setCopy(c.args.copy, currentLang, header, subheader);
    }
    if (closeIcon) {
      closeIcon.addEventListener('click', (e: any) => {
        closeMsg(msg, c);
      });
    }

    msg.style.setProperty('z-index', '9999');
    msg.style.setProperty('opacity', '0');
    msg.style.setProperty('display', 'block');

    setTimeout(() => {
      msg.style.setProperty('transition', 'opacity 0.5s ease-out');
      msg.style.setProperty('opacity', '1');
    }, c.args.delay);
    if (c.args.forever) {
      return;
    }
    setTimeout(() => {
      closeMsg(msg, c);
    }, c.args.delay + c.args.openDuration + 500);
  }
};

export default withTryCatch(async function (c: ToastMessage) {
  const validated = (await validateConfig(c, toastMessageJson)) as ToastMessage;
  if (!window.Weglot) return;
  if (!window.Weglot.initialized) {
    window.Weglot.on('initialized', () => main(validated));
  } else {
    main(validated);
  }
  window.Weglot.on('languageChanged', () => onLangChange(validated));
});
