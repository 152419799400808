import { delay, forEachKeyVal, mapKeyVal } from './utilities';

export const addCssToContainer = (containerSelector: string, css: any) => {
  const container = document.querySelector(containerSelector);
  if (container) {
    container.classList.add(css);
  }
};

export const makeScript = (
  src: string,
  attributes: { [key: string]: any } | null = null
) => {
  let newScript = document.createElement('script');
  newScript.src = src;
  newScript.type = 'text/javascript';
  if (attributes) {
    forEachKeyVal(attributes, (key: string, value: any) =>
      newScript.setAttribute(key, value)
    );
  }
  return newScript;
};

export const addScriptBody = (
  src: string,
  attributes: { [key: string]: any } | null = null
) => {
  const newScript = makeScript(src, attributes);
  document.body.appendChild(newScript);
};

export const addScriptToHead = (
  src: string,
  attributes: { [key: string]: any } | null = null
) => {
  const newScript = makeScript(src, attributes);
  document.head.appendChild(newScript);
};

export const addJsToHead = (innerHTML: string) => {
  let newScript = document.createElement('script');
  newScript.innerHTML = innerHTML;
  newScript.type = 'text/javascript';
  document.head.appendChild(newScript);
};

export const pollForGlobal = async ({
  globalName,
  delta = 100,
  maxTime = 10000,
}: {
  globalName: string;
  delta?: number;
  maxTime?: number;
}) => {
  let time = 0;
  while (time < maxTime) {
    //@ts-ignore
    const globalVar = window[globalName];
    if (globalVar) {
      break;
    } else {
      time = time + delta;
      await delay(delta);
    }
  }
  if (time >= maxTime) {
    return { success: false };
  }
  return { success: true };
};
