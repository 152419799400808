import { IAdditionalOptions, TConfigs } from '../init';
import AJV from 'ajv';

export const forEachKeyVal = <T, K extends Extract<keyof T, string>>(
  obj: T,
  cb: (key: K, value: any) => any
) => {
  return (Object.keys(obj) as Array<K>).forEach((key: K) => cb(key, obj[key]));
};

export const mapKeyVal = (
  obj: { [key: string]: any },
  cb: (key: string, value: any) => { [key: string]: any }
) =>
  Object.keys(obj).reduce(
    (prev, curr) => ({ ...prev, ...cb(curr, obj[curr]) }),
    {}
  );

export const pick = (keys: Array<string>, object: { [key: string]: any }) =>
  keys.reduce((prev, current) => {
    if (!object[current]) {
      throw new Error('Key is not in object');
    }
    return { ...prev, ...{ [current]: object[current] } };
  }, {});

export const dontPick = (
  keys: Array<string>,
  object: { [key: string]: any }
) => {
  keys.forEach(key => {
    if (!object[key]) {
      throw new Error('Key is not in object');
    }
  });
  return Object.keys(object).reduce((prev, current) => {
    return keys.includes(current)
      ? { ...prev }
      : { ...prev, ...{ [current]: object[current] } };
  }, {});
};

export const validateConfig = async (
  config: TConfigs & IAdditionalOptions,
  schemaJson: any
) => {
  const initialTime = performance.now();
  const ajv = new AJV({
    useDefaults: true,
  });
  const validate = ajv.compile({ $async: 'true', ...schemaJson });
  const isValid = await validate(config);
  if (!isValid) {
    const errorMsg = JSON.stringify(validate?.errors || '');
    throw new Error(errorMsg || 'Validation failed');
  }
  const finalTime = performance.now();
  if (config.benchMarkPerf) {
    console.log(
      `validation of ${config.module} took ${finalTime - initialTime} ms`
    );
  }
  return config;
};

export const withTryCatch = <T>(cb: (c: T) => any) => async (c: T) => {
  try {
    await cb(c);
  } catch (err) {
    console.error(err);
  }
};

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
