import { validateConfig, withTryCatch } from './helpers/utilities';
import { CloseAccordions } from '../types/generated/closeAccordions.schema';
import closeAccordionsJson from '../schemas/closeAccordions.schema.json';
import { ignoreNextOnError } from '@sentry/browser/dist/helpers';

export default withTryCatch(async (c: CloseAccordions) => {
  const validated = (await validateConfig(
    c,
    closeAccordionsJson
  )) as CloseAccordions;

  const accordionWrapperSelector = validated?.args?.accordionWrapper;
  const disableScrollTo = validated?.args?.disableScrollTo;

  document.querySelectorAll(accordionWrapperSelector).forEach(wrapperNode => {
    wrapperNode.querySelectorAll('.dropdown').forEach(accordionItem => {
      const accordionHeader = accordionItem.querySelector('.dropdown-header');

      accordionHeader?.addEventListener('click', e => {
        const target = e.target as HTMLElement;

        const activeDropdown = target.closest('.dropdown') as HTMLElement;
        const activeBody = activeDropdown.querySelector(
          '.dropdown-body'
        ) as HTMLElement;
        const activeIcon = activeDropdown.querySelector(
          '.dropdown-icon'
        ) as HTMLElement;

        const isOpen = activeBody.classList.contains('accordion-open');

        wrapperNode.querySelectorAll('.dropdown-body')?.forEach(bodyItem => {
          const bi = bodyItem as HTMLElement;
          bi.classList.remove('accordion-open');
        });

        wrapperNode
          .querySelectorAll('.dropdown-icon')
          ?.forEach(dropdownIcon => {
            const di = dropdownIcon as HTMLElement;
            di.classList.remove('rotate-plus');
          });

        if (isOpen) {
          activeBody.classList.remove('accordion-open');
          activeIcon.classList.remove('rotate-plus');
        } else {
          activeBody.classList.add('accordion-open');
          activeIcon.classList.add('rotate-plus');
          if (!disableScrollTo) {
            setTimeout(() => {
              accordionHeader.scrollIntoView({
                behavior: 'smooth',
              });
            }, 500);
          }
        }
      });
    });
  });
});
