import { validateConfig, withTryCatch } from './helpers/utilities';
import navbarJson from '../schemas/navbar.schema.json';
import { Navbar } from '../types/generated/navbar.schema';

export default withTryCatch(async (c: Navbar) => {
  await validateConfig(c, navbarJson);

  const navLinks = document.querySelectorAll(
    c?.selectors?.navLinkSelector as string
  );

  navLinks.forEach(
    link =>
      link.getAttribute(c?.args?.dataAttrib) === window.location.pathname &&
      link.classList.add(c?.args?.className)
  );
});
