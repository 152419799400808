import { TotalAmount } from '../types/generated/totalAmount.schema';
import { validateConfig, withTryCatch } from './helpers/utilities';
import totalAmountJson from '../schemas/totalAmount.schema.json';
import Errors from './helpers/errors';
import * as Sentry from '@sentry/browser';

const getTotalAmount = async (
  url: string,
  usePending: boolean,
  digits: number
) => {
  try {
    const resJson = await fetch(url).then(res => res.json());
    return (
      ((usePending
        ? resJson.potentialAmountInCents
        : resJson.totalAmountInCents) /
        100 +
        3874477) /
      1000000
    ).toFixed(digits);
  } catch (e) {
    Sentry.captureException(Errors.requestFailed(url));
    throw Errors.requestFailed(url);
  }
};

const displayTotalAmount = (elements: NodeList, amount: string) => {
  elements.forEach((val: Node) => {
    val.textContent = amount;
  });
};

const totalAmount = async (c: TotalAmount) => {
  const validated = (await validateConfig(c, totalAmountJson)) as TotalAmount;

  const elements = document.querySelectorAll(
    validated.selectors.totalAmountSelector
  );

  if (!elements.length) {
    throw Errors.selectorNotFound(validated.selectors.totalAmountSelector);
  }

  const main = async () => {
    const amount = await getTotalAmount(
      validated.args.endpointUrl,
      validated.args.countPendingContributions,
      validated.args.digits
    );
    if (!amount) {
      return;
    }
    displayTotalAmount(elements, amount);
  };

  await main();
  setInterval(main, validated.args.pollingInterval);
};

export default withTryCatch(totalAmount);
