import { addJsToHead, addScriptToHead } from '../helpers/domHelpers';
import { TRACKING_LINKS } from '../config/config';

export default () => {
  addScriptToHead(TRACKING_LINKS.googleTagManager);
  addJsToHead(`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${window.AW_CONVERSION_ID || 'AW-755222937'}');
  `);
};
