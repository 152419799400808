import { DOMTest } from '../types/generated/domTest.schema';
import { validateConfig, withTryCatch } from './helpers/utilities';
import domTestJson from '../schemas/domTest.schema.json';
import * as Sentry from '@sentry/browser';
import { DOM_TEST_BASE_ERROR_MSG } from './config/config';
import { initSentry } from './helpers/sentry';

export default withTryCatch(async (c: DOMTest) => {
  if (!window.SentryInitialised) {
    initSentry();
  }
  await validateConfig(c, domTestJson);

  const checkIfPresent = (selector: string) => {
    const path = window.location.pathname;
    const containers = Array.from(document.querySelectorAll(selector));

    // CHECK IF PRESENT AT ALL
    if (containers.length === 0) {
      Sentry.captureException(
        new Error(
          DOM_TEST_BASE_ERROR_MSG +
            `"${selector}" is missing completely from "${path}"`
        )
      );
    }

    // IF PRESENT, CHECK IF ANY ARE HIDDEN
    let instanceCount = 0;
    containers.forEach(container => {
      const style = getComputedStyle(container);
      if (style.display === 'none' || style.visibility === 'hidden') {
        instanceCount++;
      }
    });
    if (instanceCount > 0) {
      Sentry.captureException(
        new Error(
          DOM_TEST_BASE_ERROR_MSG +
            `"${selector}" is hidden in ${instanceCount} instance(s) from "${path}"`
        )
      );
    }
  };

  c.selectors.forEach(selector => checkIfPresent(selector));
});
